import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { Box, Button, ButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MainCard } from "../../../../components/cards";
import { Participant } from "../../../../models/participant";
import dayjs from "dayjs";
import { PARTICIPANTS_TITLE } from "../../../../constants";

interface ParticipantsTableProps {
  title: string;
  participants: Participant[];
}

const ParticipantsTable: React.FC<ParticipantsTableProps> = ({ title, participants }) => {
  const navigate = useNavigate();
  const showEntryDate = title === PARTICIPANTS_TITLE.LATE_START;

  const renderTableRow = (participant: Participant): React.ReactNode => {
    const entryDate = dayjs(participant.entryDate);

    return (
      <TableRow key={participant.id} hover>
        <TableCell>{participant.tsId}</TableCell>
        <TableCell>{participant.firstName}</TableCell>
        <TableCell>{participant.lastName}</TableCell>
        <TableCell>{participant.clientNumber}</TableCell>
        {showEntryDate && (
          <TableCell>
            <Trans i18nKey="COURSES.ENTRY_DATE">{entryDate.format("DD.MM.YYYY")}</Trans>
          </TableCell>
        )}
        <TableCell align="right">
          <ButtonGroup>
            <Button variant="contained" onClick={() => navigate(`/teilnehmer/${participant.id}`)}>
              <Trans i18nKey="COURSES.BUTTONS.Details">Details</Trans>
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  };

  const renderEmptyRow = (): React.ReactNode => (
    <TableRow>
      <TableCell colSpan={5} align="center">
        <Trans i18nKey="COURSES.NO_PARTICIPANTS">Keine Teilnehmer für diese Kategorie vorhanden</Trans>
      </TableCell>
    </TableRow>
  );

  return (
    <MainCard title={title} sx={{ mt: 2, height: "auto" }}>
      <TableContainer component={Box} sx={{ maxHeight: "none" }}>
        <Table size="small" aria-label={`${title} table`} border={0}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans i18nKey="ID">TS-ID</Trans>
              </TableCell>
              <TableCell>
                <Trans i18nKey="COMMON.WORDS.First Name">First Name</Trans>
              </TableCell>
              <TableCell>
                <Trans i18nKey="COMMON.WORDS.Last Name">Last Name</Trans>
              </TableCell>
              <TableCell>
                <Trans i18nKey="COMMON.WORDS.Client Number">Client number</Trans>
              </TableCell>
              {showEntryDate && (
                <TableCell>
                  <Trans i18nKey="COURSES.ENTRY_DATE">Eintrittsdatum</Trans>
                </TableCell>
              )}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.length > 0 ? participants.map(participant => renderTableRow(participant)) : renderEmptyRow()}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default ParticipantsTable;
