import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Grid, Tooltip } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridColumnGroup,
  GridRenderEditCellParams,
  GridFilterModel
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

// TS-Hub
import { ApiParticipantService } from "../../services/apiParticipantService";
import { PresencePeriod, PresenceStatus, PresenceTracking } from "../../models/presenceTracking";
import { HomeworkTracking } from "../../models/homeworkTracking";
import { Course } from "../../models/course";
import { Participant } from "../../models/participant";
import { getInfinitePaginatedData } from "../../helper/http";
import { MainCard } from "../cards";
import { useSnackbar } from "../../provider/snackbar";
import { PresenceTrackingIcon } from "./components/presenceTrackingIcon";
import { PresenceTrackingCellEdit } from "./components/presenceTrackingCellEdit";
import { getDaysBetween, isWeekday } from "../../helper/date";
import { HomeworkTrackingIcon } from "./components/homeworkTrackingIcon";
import { ColumnData, ColumnDataRecord } from "./components/interfaces";
import { HomeworkTrackingCellEdit } from "./components/homeworkTrackingCellEdit";

type ColumnIds = {
  morning: string;
  afternoon: string;
  homework: string;
};

type PresenceTrackingTableProps = {
  course?: Course;
  participant?: Participant;
};

/**
 * Creates the column ids.
 *
 * @param date
 */
function createColumnId(date: Dayjs): ColumnIds {
  return {
    morning: date.format("DD.MM.YYYY") + " M",
    afternoon: date.format("DD.MM.YYYY") + " A",
    homework: date.format("DD.MM.YYYY") + " HW"
  } as ColumnIds;
}

export const PresenceTrackingTable: React.FC<PresenceTrackingTableProps> = props => {
  const [trackingDateFrom, setTrackingDateFrom] = useState<Dayjs>(dayjs().add(-1, "day"));
  const [trackingDateUntil, setTrackingDateUntil] = useState<Dayjs>(dayjs());
  const [columns, setColumns] = useState<Array<GridColDef>>([]);
  const [columnGroups, setColumnGroups] = useState<Array<GridColumnGroup>>([]);
  const [tableData, setTableData] = useState<Array<ColumnData>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: []
  });
  const { t } = useTranslation();
  const { toast } = useSnackbar();
  const { course, participant } = props;
  const tableStyles = {
    [`& .${gridClasses.cell}`]: {
      py: 1
    },
    [`& .${gridClasses.row} div:nth-child(3n)`]: {
      borderRight: "1px double"
    }
  };
  const tableInitialState: GridInitialStatePro = {
    columns: { columnVisibilityModel: { id: false } },
    sorting: { sortModel: [{ field: "firstName", sort: "asc" }] },
    pinnedColumns: {
      left: ["tsId", "hubspotLeadStatus", "firstName", "lastName", "countPresenceInUE", "countVacationTotal"]
    }
  };

  const DEFAULT_FILTER = {
    field: "hubspotLeadStatus",
    operator: "is",
    value: "Kursteilnehmer BGS"
  };

  /**
   * useEffect to fetch data.
   *
   */
  useEffect(() => {
    setIsLoading(true);

    fetchPresenceTracking().then(async presenceTracking => {
      const homeworkTracking = await fetchHomeworkTracking();
      createTableData(presenceTracking, homeworkTracking);
      setIsLoading(false);
    });
  }, [trackingDateFrom, trackingDateUntil]);

  /**
   * Sets the initial filter to the default status to only show participants with this status.
   */
  useEffect(() => {
    setFilterModel({
      items: [DEFAULT_FILTER]
    });
  }, []);

  /**
   * Fetch presence tracking.
   *
   */
  async function fetchPresenceTracking() {
    return await getInfinitePaginatedData<PresenceTracking>(
      ApiParticipantService.fetchPresenceTracking,
      [],
      getQueryParams()
    ).then(res => {
      return res.data.map(r => new PresenceTracking(r));
    });
  }

  /**
   * Fetch homework tracking.
   *
   */
  async function fetchHomeworkTracking() {
    return await getInfinitePaginatedData<HomeworkTracking>(
      ApiParticipantService.fetchHomeworkTracking,
      [],
      getQueryParams()
    ).then(res => {
      return res.data.map(r => new HomeworkTracking(r));
    });
  }

  /**
   * Creates and returns the appropriate query parameters.
   *
   */
  function getQueryParams() {
    if (course) {
      return new URLSearchParams({
        date_range_after: trackingDateFrom.format("YYYY-MM-DD"),
        date_range_before: trackingDateUntil.format("YYYY-MM-DD"),
        course: String(course.id)
      });
    } else if (participant) {
      return new URLSearchParams({
        date_range_after: trackingDateFrom.format("YYYY-MM-DD"),
        date_range_before: trackingDateUntil.format("YYYY-MM-DD"),
        participant: String(participant.id)
      });
    }
  }

  /**
   * Creates the default table data.
   *
   */
  function createDefaultTableData(): Array<ColumnData> {
    const tTableData: Array<ColumnData> = [];

    if (course) {
      course.participants.map(participant => {
        tTableData.push({
          id: participant.id,
          userId: participant.id,
          firstName: participant.firstName,
          lastName: participant.lastName,
          tsId: participant.tsId,
          countPresenceInUE: participant.numberOfTeachingUnitsDone || 0,
          countVacationTotal: `${participant.numberOfVacationDaysTaken || 0} / ${course.vacationDaysForPersonalUsage}`,
          countAbsenceExcusedInPeriod: 0,
          countAbsenceUnexcusedInPeriod: 0,
          countHomeworkDoneInPeriod: 0,
          hubspotLeadStatus: participant.hubspotLeadStatus || "kein Status"
        });
      });
    } else if (participant) {
      tTableData.push({
        id: participant.id,
        userId: participant.id,
        firstName: participant.firstName,
        lastName: participant.lastName,
        tsId: participant.tsId,
        countPresenceInUE: participant.numberOfTeachingUnitsDone || 0,
        countVacationTotal: participant.numberOfVacationDaysTaken || 0,
        countAbsenceExcusedInPeriod: 0,
        countAbsenceUnexcusedInPeriod: 0,
        countHomeworkDoneInPeriod: 0,
        hubspotLeadStatus: participant.hubspotLeadStatus || "kein Status"
      });
    }

    return tTableData;
  }

  /**
   * Get a single presence tracking from the list.
   *
   * @param day
   * @param presenceTracking
   * @param period
   * @param userId
   */
  function getPresenceTrackingData(
    day: Dayjs,
    presenceTracking: Array<PresenceTracking>,
    period: PresencePeriod,
    userId: number
  ): ColumnDataRecord {
    const pt = presenceTracking.find(
      p =>
        p.date.date() === day.date() &&
        p.date.month() === day.month() &&
        p.date.year() === day.year() &&
        p.period === period &&
        p.participant.id === userId
    );

    return {
      isPresenceTracking: true,
      presenceTrackingId: pt ? pt.id : null,
      presenceTrackingStatus: pt ? pt.status : null,
      presenceTrackingComment: pt ? pt.comment : null,
      isHomeworkTracking: false,
      homeworkTrackingId: null,
      homeworkTrackingStatus: null,
      homeworkTrackingComment: null
    } as ColumnDataRecord;
  }

  /**
   * Get a single homework tracking from the list.
   *
   * @param day
   * @param homeworkTracking
   * @param userId
   */
  function getHomeworkTrackingData(day: Dayjs, homeworkTracking: Array<HomeworkTracking>, userId: number): ColumnDataRecord {
    const ht = homeworkTracking.find(
      h =>
        h.date.date() === day.date() &&
        h.date.month() === day.month() &&
        h.date.year() === day.year() &&
        h.participant.id === userId
    );

    return {
      isPresenceTracking: false,
      presenceTrackingId: null,
      presenceTrackingStatus: null,
      presenceTrackingComment: null,
      isHomeworkTracking: true,
      homeworkTrackingId: ht ? ht.id : null,
      homeworkTrackingStatus: ht ? ht.status : null,
      homeworkTrackingComment: ht ? ht.comment : null
    } as ColumnDataRecord;
  }

  /**
   * Function that creates the table data.
   *
   */
  function createTableData(presenceTracking: Array<PresenceTracking>, homeworkTracking: Array<HomeworkTracking>) {
    const tDates: Array<Dayjs> = [];
    const tDatesAsString: Array<string> = [];
    const tTableData: Array<ColumnData> = createDefaultTableData();
    const daysBetween = getDaysBetween(trackingDateFrom, trackingDateUntil);

    tTableData.forEach(tData => {
      let tAbsentExcused = 0;
      let tAbsentUnexcused = 0;
      let tHomworkDone = 0;

      daysBetween.forEach(day => {
        if (isWeekday(day.toDate())) {
          const columnIds = createColumnId(day);
          const dateAsString = day.format("DD.MM.YYYY");

          const trackingDataMorning = getPresenceTrackingData(day, presenceTracking, PresencePeriod.MORNING, tData.userId);
          const trackingDataAfternoon = getPresenceTrackingData(day, presenceTracking, PresencePeriod.AFTERNOON, tData.userId);
          const trackingDataHomework = getHomeworkTrackingData(day, homeworkTracking, tData.userId);

          tData[columnIds.morning] = trackingDataMorning;
          tData[columnIds.afternoon] = trackingDataAfternoon;
          tData[columnIds.homework] = trackingDataHomework;

          // Count for absent excused
          if (trackingDataMorning.presenceTrackingStatus === "e") tAbsentExcused += 0.5;
          if (trackingDataAfternoon.presenceTrackingStatus === "e") tAbsentExcused += 0.5;

          // Count for absent not excused
          if (trackingDataMorning.presenceTrackingStatus === "a") tAbsentUnexcused += 0.5;
          if (trackingDataAfternoon.presenceTrackingStatus === "a") tAbsentUnexcused += 0.5;

          // Count for homework
          if (trackingDataHomework.homeworkTrackingStatus === true) tHomworkDone += 1;

          // Save the date into tDates
          if (!tDatesAsString.includes(dateAsString)) {
            tDatesAsString.push(dateAsString);
            tDates.push(day);
          }
        }
      });

      tData.countAbsenceExcusedInPeriod = tAbsentExcused;
      tData.countAbsenceUnexcusedInPeriod = tAbsentUnexcused;
      tData.countHomeworkDoneInPeriod = tHomworkDone;
    });

    setTableData(tTableData);
    createColumns(tDates);
    createColumnGroups(tDates);
  }

  /**
   * Function that creates the table columns.
   *
   */
  function createColumns(dates: Array<Dayjs>) {
    const tDates: Array<string> = [];
    const sortedDates = dates.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));

    sortedDates.forEach(d => {
      const columnIds = createColumnId(d);
      tDates.push(columnIds.morning);
      tDates.push(columnIds.afternoon);
      tDates.push(columnIds.homework);
    });

    setColumns([
      { field: "id", headerName: "ID", width: 30 },
      { field: "tsId", headerName: "ID", width: 130 },
      {
        field: "hubspotLeadStatus",
        headerName: `${t("COMMON.WORDS.HubSpot Lead Status")}`,
        width: 150,
        type: "singleSelect",
        valueOptions: ["Kursteilnehmer BGS", "Dropout", "Nicht-Antritt", "Teil-BGS nicht verlängert", "Absolvent:in"],
        getOptionLabel: (option: any) => (option === "" ? "Alle" : option)
      },
      { field: "firstName", headerName: `${t("COMMON.WORDS.First Name")}`, width: 200 },
      { field: "lastName", headerName: `${t("COMMON.WORDS.Last Name")}`, width: 200 },
      { field: "countPresenceInUE", headerName: "UE Anw.", width: 100 },
      { field: "countVacationTotal", headerName: "Urlaub", width: 100 },
      { field: "countAbsenceExcusedInPeriod", headerName: "Abw. Ent.", width: 100 },
      { field: "countAbsenceUnexcusedInPeriod", headerName: "Abw. Unent.", width: 100 },
      { field: "countHomeworkDoneInPeriod", headerName: "HA gemacht", width: 100 },
      ...tDates.map(td => {
        const end = td.split(" ")[1];

        return {
          field: td,
          renderHeader: () => {
            switch (end) {
              case "M":
                return (
                  <Tooltip
                    title={"Der Vormittag geht von 09:00 - 13:00 Uhr. Die Anwesenheit bezieht sich auf den gesamten Zeitraum."}
                  >
                    <span>09:00</span>
                  </Tooltip>
                );
              case "A":
                return (
                  <Tooltip
                    title={"Der Nachmittag geht von 13:30 - 17:00 Uhr. Die Anwesenheit bezieht sich auf den gesamten Zeitraum."}
                  >
                    <span>13:30</span>
                  </Tooltip>
                );
              case "HW":
                return (
                  <Tooltip
                    title={
                      "Zeigt an, ob es an dem Tag Hausaufgaben gab. Wenn ja, sind sie etwas gemacht oder nicht gemacht. " +
                      "Ein gelber Strich bedeutet, dass an dem Tag keine Hausaufgaben aufgegeben wurden."
                    }
                  >
                    <span>HA</span>
                  </Tooltip>
                );
            }
          },
          width: 60,
          align: "center",
          editable: true,
          valueFormatter: params => (params.value ? params.value.status : null),
          renderCell: params => renderCell(params.row[td] as ColumnDataRecord),
          renderEditCell: renderEditComponent
        } as GridColDef;
      })
    ]);
  }

  /**
   *
   */
  function createColumnGroups(dates: Array<Dayjs>) {
    const columnGroups: Array<GridColumnGroup> = [];

    columnGroups.push({
      groupId: "Zusammenfassung",
      children: [
        { field: "countAbsenceExcusedInPeriod" },
        { field: "countAbsenceUnexcusedInPeriod" },
        { field: "countHomeworkDoneInPeriod" }
      ]
    });

    dates.forEach(date => {
      const columnIds = createColumnId(date);

      columnGroups.push({
        groupId: date.format("dddd, DD.MM.YYYY"),
        children: [{ field: columnIds.morning }, { field: columnIds.afternoon }, { field: columnIds.homework }]
      });
    });

    setColumnGroups(columnGroups);
  }

  /**
   * Renders the single cell.
   *
   * @param data
   */
  function renderCell(data: ColumnDataRecord) {
    if (data.isPresenceTracking) {
      return <PresenceTrackingIcon status={data.presenceTrackingStatus} comment={data.presenceTrackingComment} />;
    }

    if (data.isHomeworkTracking) {
      return <HomeworkTrackingIcon status={data.homeworkTrackingStatus} />;
    }
  }

  /**
   * Renders the edit mode of a single cell.
   *
   * @param params
   */
  function renderEditComponent(params: GridRenderEditCellParams) {
    if (params.value.isPresenceTracking) {
      if (params.value.presenceTrackingStatus === PresenceStatus.HOLIDAY) {
        toast({ level: "warning", message: "Feiertage sind nicht editiertbar!" });
        return null;
      } else if (!params.value.presenceTrackingStatus) {
        toast({ level: "warning", message: "Leere Einträge sind nicht editiertbar!" });
        return null;
      } else {
        return <PresenceTrackingCellEdit params={params} />;
      }
    }

    if (params.value.isHomeworkTracking) {
      if (!params.value.homeworkTrackingId) {
        toast({ level: "warning", message: "Leere Hausaufgaben sind nicht editiertbar!" });
        return null;
      } else {
        return <HomeworkTrackingCellEdit params={params} />;
      }
    }

    return null;
  }

  /**
   * Set the new trackingDateFrom and make sure that trackingDateUntil is min. three days behind.
   *
   * @param newDate
   */
  function handleTrackingDateFromOnChange(newDate: Dayjs) {
    setTrackingDateFrom(newDate);
    if (newDate.isAfter(trackingDateUntil)) {
      setTrackingDateUntil(newDate.add(3, "days"));
    }
  }

  /**
   * Set the new trackingDateUntil and make sure that trackingDateFrom is min. three days after.
   *
   * @param newDate
   */
  function handleTrackingDateUntilOnChange(newDate: Dayjs) {
    setTrackingDateUntil(newDate);
    if (newDate.isBefore(trackingDateFrom)) {
      setTrackingDateFrom(newDate.add(-3, "days"));
    }
  }

  /**
   * This function will be triggered, after a cell was updated.
   *
   * @param updatedRow
   * @param originalRow
   */
  function onRowUpdate(updatedRow: Record<string, ColumnDataRecord>, originalRow: Record<string, ColumnDataRecord>) {
    const rowKeys = Object.keys(updatedRow);
    const changedPresenceTracking: Array<string> = [];
    const changedHomeworkTracking: Array<string> = [];

    // Filter every single item that was changed, because we don't want to edit/update/delete something that
    // was not edited before.
    rowKeys.forEach(key => {
      if (updatedRow[key].presenceTrackingStatus !== originalRow[key].presenceTrackingStatus) {
        changedPresenceTracking.push(key);
      }

      if (updatedRow[key].homeworkTrackingStatus !== originalRow[key].homeworkTrackingStatus) {
        changedHomeworkTracking.push(key);
      }
    });

    handleRowUpdateForPresenceTracking(changedPresenceTracking, updatedRow);
    handleRowUpdateForHomeworkTracking(changedHomeworkTracking, updatedRow);

    if (!updatedRow.id) {
      toast({ level: "warning", message: "Für diesen Zeitraum gibt es keinen Eintrag. Bitte erstelle zunächst einen." });
    }

    return updatedRow;
  }

  /**
   * Special handler to update presence tracking.
   *
   * @param changedPresenceTracking
   * @param updatedRow
   */
  function handleRowUpdateForPresenceTracking(
    changedPresenceTracking: Array<string>,
    updatedRow: Record<string, ColumnDataRecord>
  ) {
    changedPresenceTracking.map(key => {
      const record = updatedRow[key];

      ApiParticipantService.updatePresenceTracking(record.presenceTrackingId!, {
        status: record.presenceTrackingStatus as PresenceStatus,
        comment: record.presenceTrackingComment as string
      }).then(res => {
        if (res.status === 200) {
          toast({ level: "success", message: "Anwesenheit wurde erfolgreich aktualisiert." });
        } else {
          toast({ level: "error", message: "Anwesenheit wurde nicht erfolgreich aktualisiert." });
        }
      });
    });
  }

  /**
   * Special handler to update presence tracking.
   * @param changedHomeworkTracking
   * @param updatedRow
   */
  function handleRowUpdateForHomeworkTracking(
    changedHomeworkTracking: Array<string>,
    updatedRow: Record<string, ColumnDataRecord>
  ) {
    changedHomeworkTracking.map(key => {
      const record = updatedRow[key];

      if (record.homeworkTrackingStatus === null) {
        console.log("TO DELETE!");
      } else {
        ApiParticipantService.updateHomeworkTracking(record.homeworkTrackingId!, {
          status: record.homeworkTrackingStatus as boolean,
          comment: record.homeworkTrackingComment as string
        }).then(res => {
          if (res.status === 200) {
            toast({ level: "success", message: "Hausaufgabe wurde erfolgreich aktualisiert." });
          } else {
            toast({ level: "error", message: "Hausaufgabe wurde nicht erfolgreich aktualisiert." });
          }
        });
      }
    });
  }

  return (
    <React.Fragment>
      <MainCard title={"Kursmanager"} sx={{ height: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ margin: "auto 0px" }} />
          <Grid item xs={6} sm={3}>
            <DesktopDatePicker
              value={trackingDateFrom}
              label={"Von"}
              onChange={value => handleTrackingDateFromOnChange(value as Dayjs)}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DesktopDatePicker
              value={trackingDateUntil}
              label={"Bis"}
              onChange={value => handleTrackingDateUntilOnChange(value as Dayjs)}
            />
          </Grid>
        </Grid>
      </MainCard>

      <Box height={"50%"}>
        <DataGridPro
          experimentalFeatures={{ columnGrouping: true }}
          rows={tableData as Record<string, ColumnDataRecord>[]}
          columns={columns}
          pageSizeOptions={[20, 30, 50]}
          loading={isLoading}
          checkboxSelection={false}
          showCellVerticalBorder={true}
          showColumnVerticalBorder={true}
          getRowHeight={() => "auto"}
          isCellEditable={() => true}
          processRowUpdate={onRowUpdate}
          sx={{ ...tableStyles, height: "auto" }}
          initialState={tableInitialState}
          columnGroupingModel={columnGroups}
          filterModel={filterModel}
          onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
        />
      </Box>
    </React.Fragment>
  );
};
