import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// MUI
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// TS-Hub
import { Course } from "../../models/course";
import {
  getDefaultPresencePeriod,
  IPresenceTrackingCreate,
  IPresenceTrackingUpdate,
  PresencePeriod,
  PresenceStatus,
  PresenceTracking
} from "../../models/presenceTracking";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { useSnackbar } from "../../provider/snackbar";
import { getInfinitePaginatedData } from "../../helper/http";
import { Participant } from "../../models/participant";
import { CenteredBox } from "../box/centeredBox";

type PresenceTrackingModalProps = {
  isOpen: boolean;
  onClose: Function;
  course?: Course;
  participant?: Participant;
};

type PresenceTrackingCardProps = {
  presenceTracking: PresenceTracking;
  index: number;
  handleStatusOnChange: Function;
  handleCommentOnChange: Function;
};

export const PresenceTrackingModal: React.FC<PresenceTrackingModalProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [trackingDate, setTrackingDate] = useState<Dayjs>(dayjs());
  const [presenceTracks, setPresenceTrack] = useState<Array<PresenceTracking>>([]);
  const [originPresenceTracks, setOriginPresenceTrack] = useState<Array<PresenceTracking>>([]);
  const [defaultPeriod, setDefaultPeriod] = useState<PresencePeriod>(getDefaultPresencePeriod());
  const [group, setGroup] = useState<number>(0);
  const [isTrackingAlreadyCreated, setIsTrackingAlreadyCreated] = useState(false);
  const { t } = useTranslation();
  const { toast } = useSnackbar();
  const { isOpen, onClose, course, participant } = props;

  /**
   * useEffect
   *
   */
  useEffect(() => {
    if (isOpen) {
      fetchOrCreatePresenceTracking();
    }
  }, [trackingDate, defaultPeriod, isOpen, group]);

  /**
   * Fetch the presence tracking from the backend or create new ones to work with them.
   *
   */
  async function fetchOrCreatePresenceTracking(period: PresencePeriod = defaultPeriod) {
    const res = await getInfinitePaginatedData<PresenceTracking>(
      ApiParticipantService.fetchPresenceTracking,
      [],
      getQueryParams(period)
    );

    const tmpDownloadedTracking = res.data.map(r => new PresenceTracking(r));
    const tmpDefaultTracking = createDefaultPresenceTracking();
    const tmpTracking = tmpDefaultTracking.map(tr => {
      const existingTracking = tmpDownloadedTracking.find(dt => dt.participant.id === tr.participant.id);
      if (existingTracking) return existingTracking;
      return tr;
    });

    setPresenceTrack(tmpTracking);
    setOriginPresenceTrack(tmpTracking.map(a => new PresenceTracking(a)));

    if (_.isEmpty(res)) {
      // Create new empty PresenceTracking
      setIsTrackingAlreadyCreated(false);
    } else {
      // Set the states with the fetched data
      setIsTrackingAlreadyCreated(true);
    }
  }

  /**
   * Creates the default values for new presence tracking.
   *
   */
  function createDefaultPresenceTracking(): Array<PresenceTracking> {
    if (participant) {
      return [
        new PresenceTracking({
          participant: participant,
          status: PresenceStatus.PRESENT,
          date: trackingDate,
          period: defaultPeriod,
          comment: ""
        })
      ];
    } else if (course) {
      const tmp: Array<PresenceTracking> = [];
      course.activeParticipants.forEach(participant => {
        if (!group || (group && participant.groups.includes(group))) {
          tmp.push(
            new PresenceTracking({
              participant: participant,
              status: PresenceStatus.PRESENT,
              date: trackingDate,
              period: defaultPeriod,
              comment: ""
            })
          );
        }
      });
      return tmp;
    } else {
      return [];
    }
  }

  /**
   * Creates and returns the appropriate query parameters.
   *
   */
  function getQueryParams(period: PresencePeriod) {
    if (participant) {
      return new URLSearchParams({
        date_range_after: trackingDate.format("YYYY-MM-DD"),
        date_range_before: trackingDate.format("YYYY-MM-DD"),
        participant: String(participant.id),
        period: String(period)
      });
    } else if (course) {
      return new URLSearchParams({
        date_range_after: trackingDate.format("YYYY-MM-DD"),
        date_range_before: trackingDate.format("YYYY-MM-DD"),
        course: String(course.id),
        period: String(period)
      });
    }
  }

  /**
   * Create a new presence tracking.
   *
   * @param tracking
   */
  async function updatePresenceTracking(tracking: PresenceTracking): Promise<boolean> {
    const presenceTrackingToCreate: IPresenceTrackingUpdate = {
      status: tracking.status,
      period: defaultPeriod,
      comment: tracking.comment
    };
    const res = await ApiParticipantService.updatePresenceTracking(tracking.id!, presenceTrackingToCreate);
    return res.status === 200;
  }

  /**
   * Update a presence tracking.
   *
   * @param tracking
   */
  async function createPresenceTracking(tracking: PresenceTracking): Promise<boolean> {
    const presenceTrackingToCreate: IPresenceTrackingCreate = {
      participant: tracking.participant.id,
      date: tracking.date.format("YYYY-MM-DD"),
      status: tracking.status,
      period: defaultPeriod,
      comment: tracking.comment
    };
    const res = await ApiParticipantService.createPresenceTracking(presenceTrackingToCreate);
    return res.status === 201;
  }

  /**
   * Handler for the onSubmit event.
   *
   */
  async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    let success = null;

    for (let i = 0; i < presenceTracks.length; i++) {
      const tracking = presenceTracks[i];

      if (tracking.id) {
        // Presence tracking was already created - update it only then, if the status changed
        if (tracking.status !== originPresenceTracks[i].status || tracking.comment !== originPresenceTracks[i].comment) {
          success = await updatePresenceTracking(tracking);
        }
      } else {
        // Create new presence tracking
        success = await createPresenceTracking(tracking);
      }

      if (success === false) {
        toast({
          message: `${t("PRESENCE_TRACKING.TOASTS.CREATED_UNSUCCESSFULLY")}: ${tracking.participant.firstName} ${
            tracking.participant.lastName
          }`,
          level: "error"
        });
      }
    }

    if (success) {
      toast({ message: t("PRESENCE_TRACKING.TOASTS.CREATED_SUCCESSFULLY"), level: "success" });
      fetchOrCreatePresenceTracking();
    }

    setIsLoading(false);
  }

  /**
   * Handles the onChange event of the select form for the period.
   *
   * @param period
   */
  function handleDefaultPeriodOnChange(period: PresencePeriod) {
    const tmpPresenceTracks = [...presenceTracks];
    const tmpPresenceTracks2 = tmpPresenceTracks.map(t => {
      t.period = period;
      return t;
    });
    setDefaultPeriod(period);
    setPresenceTrack(tmpPresenceTracks2);
  }

  /**
   * Handles the onChange event of the select form.
   *
   * This is the only way how we can update an object in an array made by useState.
   *
   * I followed these docs: https://beta.reactjs.org/learn/updating-arrays-in-state#updating-objects-inside-arrays
   *
   * @param index
   * @param status
   */
  function handleStatusOnChange(index: number, status: PresenceStatus) {
    const t = [...presenceTracks];
    const t1 = t[index];
    t1.status = status as PresenceStatus;
    setPresenceTrack(t);
  }

  /**
   * Handles the onChange event of the comment form.
   *
   * This is the only way how we can update an object in an array made by useState.
   *
   * I followed these docs: https://beta.reactjs.org/learn/updating-arrays-in-state#updating-objects-inside-arrays
   *
   * @param event
   * @param index
   */
  function handleCommentOnChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) {
    const t = [...presenceTracks];
    const t1 = t[index];
    t1.comment = event.target.value || "";
    setPresenceTrack(t);
  }

  /**
   * Handles the onClose event of the modal.
   *
   */
  function handleOnClose() {
    setIsLoading(false);
    onClose();
    setPresenceTrack([]);
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose} maxWidth={"md"} fullWidth={true}>
      <form onSubmit={handleOnSubmit}>
        <DialogTitle textAlign={"center"}>
          {t("COMMON.WORDS.Presence Tracking")}
          {isTrackingAlreadyCreated && (
            <Tooltip title={"Anwesenheiten bereits ermittelt!"}>
              <CheckCircleIcon color={"success"} style={{ marginLeft: "5px", padding: "3px" }} />
            </Tooltip>
          )}
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Box>
            <Grid container marginBottom={"15px"} marginTop={"25px"}>
              <Grid item xs={6} sx={{ margin: "auto 0px" }}>
                <strong>Unterrichtstag</strong>
                <div>
                  <small>Tag an dem Unterrichtet wurde</small>
                </div>
              </Grid>
              <Grid item xs={6}>
                <DesktopDatePicker
                  value={trackingDate}
                  onChange={value => setTrackingDate(value as Dayjs)}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Grid container marginBottom={"35px"} marginTop={"15px"}>
              <Grid item xs={6} sx={{ margin: "auto 0px" }}>
                <strong>Zeitraum</strong>
                <div>
                  <small>Bestimmt den Anwesenheitszeitraum (Vormittags / Nachmittags)</small>
                </div>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id={"presence-period-select-label"}>Zeitraum</InputLabel>
                  <Select
                    labelId={"presence-period-select-label"}
                    id={"presence-period-select"}
                    value={defaultPeriod}
                    label={"Zeitraum"}
                    onChange={event => handleDefaultPeriodOnChange(event.target.value as PresencePeriod)}
                  >
                    <MenuItem value={PresencePeriod.MORNING}>{t("COMMON.WORDS.Morning")}</MenuItem>
                    <MenuItem value={PresencePeriod.AFTERNOON}>{t("COMMON.WORDS.Afternoon")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {course && !_.isEmpty(course.groups) && (
              <Grid container marginBottom={"35px"} marginTop={"15px"}>
                <Grid item xs={6} sx={{ margin: "auto 0px" }}>
                  <strong>Gruppe</strong>
                  <div>
                    <small>Für eine feinere Auswahl der Teilnehmer</small>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id={"course-group-select-label"}>Gruppe</InputLabel>
                    <Select
                      labelId={"course-group-select-label"}
                      id={"course-group-select"}
                      value={group}
                      label={"Gruppe"}
                      onChange={event => setGroup(event.target.value as number)}
                    >
                      <MenuItem value={0}>Keine Gruppe ausgewählt</MenuItem>
                      {course.groups.map(group => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Divider />

            <Box maxHeight={"500px"} overflow={"auto"}>
              <Stack direction={"row"} flexWrap={"wrap"} flex={"1 1 0px"} justifyContent={"space-between"}>
                {presenceTracks.map((presenceTracking, index) => (
                  <PresenceTrackingCard
                    key={index}
                    presenceTracking={presenceTracking}
                    index={index}
                    handleStatusOnChange={handleStatusOnChange}
                    handleCommentOnChange={handleCommentOnChange}
                  />
                ))}
              </Stack>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
            {t("COMMON.WORDS.Save")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const PresenceTrackingCard: React.FC<PresenceTrackingCardProps> = ({
  presenceTracking,
  index,
  handleStatusOnChange,
  handleCommentOnChange
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOnClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuOnClose = () => setAnchorEl(null);

  return (
    <Paper key={presenceTracking.participant.id} sx={{ padding: 1, margin: 1, width: "260px" }}>
      <Stack justifyContent={"stretch"}>
        {presenceTracking.status === PresenceStatus.PRESENT && <Chip size={"small"} color={"success"} label={"Anwesend"} />}
        {presenceTracking.status === PresenceStatus.ABSENT && <Chip size={"small"} color={"error"} label={"Abwesend"} />}
        {presenceTracking.status === PresenceStatus.DELAYED && <Chip size={"small"} color={"warning"} label={"Verspätet"} />}
        {presenceTracking.status === PresenceStatus.EXCUSED && <Chip size={"small"} color={"info"} label={"Entschuldigt"} />}
        {presenceTracking.status === PresenceStatus.VACATION && <Chip size={"small"} color={"info"} label={"Urlaub"} />}
        {presenceTracking.status === PresenceStatus.HOLIDAY && <Chip size={"small"} color={"info"} label={"Feiertag"} />}
      </Stack>
      <CenteredBox>
        <h3>{presenceTracking.participant.getFullName()}</h3>
      </CenteredBox>
      <Box textAlign={"center"} marginY={"10px"}>
        <TextField
          id={`comment-${presenceTracking.participant.id}`}
          label={"Kommentar"}
          fullWidth={true}
          variant={"outlined"}
          multiline={true}
          minRows={3}
          style={{ fontSize: "8px" }}
          required={presenceTracking.status === PresenceStatus.ABSENT}
          value={presenceTracking.comment}
          onChange={event => handleCommentOnChange(event, index)}
        />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <IconButton
          onClick={() => handleStatusOnChange(index, PresenceStatus.PRESENT)}
          disabled={presenceTracking.status === PresenceStatus.PRESENT}
          color={"success"}
          title={"Anwesend"}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          onClick={() => handleStatusOnChange(index, PresenceStatus.DELAYED)}
          disabled={presenceTracking.status === PresenceStatus.DELAYED}
          color={"warning"}
          title={"Verspätet"}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          onClick={() => handleStatusOnChange(index, PresenceStatus.ABSENT)}
          disabled={presenceTracking.status === PresenceStatus.ABSENT}
          color={"error"}
          title={"Abwesend"}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <IconButton
            aria-label={"more"}
            id={"long-button"}
            aria-controls={menuOpen ? "long-menu" : undefined}
            aria-expanded={menuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleMenuOnClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={"long-menu"}
            MenuListProps={{
              "aria-labelledby": "long-button"
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuOnClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch"
              }
            }}
          >
            <MenuItem
              disabled={presenceTracking.status === PresenceStatus.EXCUSED}
              onClick={() => {
                handleStatusOnChange(index, PresenceStatus.EXCUSED);
                handleMenuOnClose();
              }}
            >
              Entschuldigt
            </MenuItem>
            <MenuItem
              disabled={presenceTracking.status === PresenceStatus.VACATION}
              onClick={() => {
                handleStatusOnChange(index, PresenceStatus.VACATION);
                handleMenuOnClose();
              }}
            >
              Urlaub
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
    </Paper>
  );
};
