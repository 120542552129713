import React from "react";

// TS-Hub
import { Course } from "../../../../models/course";
import { PARTICIPANTS_TITLE } from "../../../../constants";
import ParticipantsTable from "./ParticipantsTable";

interface ParticipantsListProps {
  course?: Course;
}

export const ParticipantsList: React.FC<ParticipantsListProps> = ({ course }) => {
  if (!course) {
    return null;
  }

  return (
    <React.Fragment>
      <ParticipantsTable title={PARTICIPANTS_TITLE.ACTIVE} participants={course.activeParticipants} />
      <ParticipantsTable title={PARTICIPANTS_TITLE.LATE_START} participants={course.lateStartParticipants} />
      <ParticipantsTable title={PARTICIPANTS_TITLE.DROPOUTS} participants={course.dropoutParticipants} />
      <ParticipantsTable title={PARTICIPANTS_TITLE.NO_ATTENDANCE} participants={course.noAttendanceParticipants} />
      <ParticipantsTable title={PARTICIPANTS_TITLE.DEAL_NOT_EXTENDED} participants={course.dealNotExtendedParticipants} />
      <ParticipantsTable title={PARTICIPANTS_TITLE.GRADUATED} participants={course.graduatedParticipants} />
    </React.Fragment>
  );
};
